// Generated by Framer (41c59c7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Or4VUOtw3", "CQ2aoJU1Z"];

const serializationHash = "framer-F4zH8"

const variantClassNames = {CQ2aoJU1Z: "framer-v-rad2hd", Or4VUOtw3: "framer-v-1jmj6r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 2, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Or4VUOtw3", "Variant 2": "CQ2aoJU1Z"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Or4VUOtw3"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Or4VUOtw3", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1jmj6r", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Or4VUOtw3"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-1aa7a1f9-664a-40d1-a64a-074a2f9865c7, rgb(255, 255, 255))", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, ...style}} {...addPropertyOverrides({CQ2aoJU1Z: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-fgr62a"} layoutDependency={layoutDependency} layoutId={"dHNEFtwIi"} style={{backgroundColor: "var(--token-2d0196d5-4c11-460d-91d6-2fb81e415efd, rgb(255, 68, 0))"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-F4zH8.framer-1w1mfpe, .framer-F4zH8 .framer-1w1mfpe { display: block; }", ".framer-F4zH8.framer-1jmj6r { height: 116px; overflow: hidden; position: relative; width: 238px; will-change: var(--framer-will-change-override, transform); }", ".framer-F4zH8 .framer-fgr62a { bottom: 0px; flex: none; height: 0%; left: 0px; overflow: hidden; position: absolute; width: 100%; }", ".framer-F4zH8.framer-v-rad2hd .framer-fgr62a { height: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 116
 * @framerIntrinsicWidth 238
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CQ2aoJU1Z":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCycr65gkQ: React.ComponentType<Props> = withCSS(Component, css, "framer-F4zH8") as typeof Component;
export default FramerCycr65gkQ;

FramerCycr65gkQ.displayName = "background change";

FramerCycr65gkQ.defaultProps = {height: 116, width: 238};

addPropertyControls(FramerCycr65gkQ, {variant: {options: ["Or4VUOtw3", "CQ2aoJU1Z"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerCycr65gkQ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})